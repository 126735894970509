.hidden-row {
  background-image: linear-gradient(
    -45deg,
    #e7e7e7 25%,
    transparent 25%,
    transparent 50%,
    #e7e7e7 50%,
    #e7e7e7 75%,
    transparent 75%,
    transparent
  );
  background-size: 10px 10px;
}
