@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@700&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn {
  padding: 15px !important;
}

.btn.gray {
  background-color: #303f46;
}

.btn-small {
  padding: 5px 10px !important;
  font-size: 12px !important;
  line-height: normal !important;
  box-shadow: none !important;
  text-transform: none !important;
}

.btn-green {
  background-color: #00ac69 !important;
  box-shadow: none !important;
  text-transform: none !important;
}

.btn-red {
  background-color: #db0b33 !important;
  box-shadow: none !important;
  text-transform: none !important;
  color: white !important;
}

.secondary-btn.btn {
  background-color: #303f46;
  padding: 5px 10px;
}

.secondary-btn.btn:hover {
  background-color: rgba(48, 63, 70, 0.9);
}

.primary-btn.btn {
  background-color: #db0b33;
  color: #fff !important;
}

.primary-btn.btn:hover {
  background-color: rgb(219, 11, 51, 0.9);
}

.green-btn.btn {
  background-color: #008652;
}

.orange-btn.btn {
  background-color: #ff6600;
}

.orange-btn.btn:hover {
  background-color: #ff6600;
}

.green-btn.btn:hover {
  background-color: rgba(0, 134, 82, 0.9);
}

.custom-vh {
  height: 70vh;
}

.bg-red {
  background-color: #db0b33;
}

.confirm-button {
  box-shadow: none !important;
  text-transform: none !important;
  padding: 0 15px !important;
}

.more-options-dialog .MuiDialog-paper {
  width: 50%;
}

@media (max-width: 768px) {
  .more-options-dialog .MuiDialog-paper {
    width: 90%;
  }
}

.prihod-value,
.odhod-value {
  position: relative;
  padding-left: 13px;
}

.prihod-value::before {
  background-color: #008652;
}

.odhod-value::before {
  background-color: #db0b33;
}

.prihod-value::before,
.odhod-value::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 50px;
  left: 0;
  top: calc(50% - 2px);
}

.mobile-menu {
  top: 67px;
}

.mobile-menu-button {
  background-color: #db0b33;
  padding: 3px 5px 5px 5px;
  border-radius: 4px;
}

.bg-primary {
  background-color: #f2f4f7;
}

.pt-67 {
  padding-top: 67px;
}
.transform-none {
  text-transform: none !important;
}

/* toggle visibility element */
#remove-p {
  background: #f6f5f5;
  padding: 2px 10px;
  border-radius: 3px;
  margin-top: 20px;
  font-size: 10px;
}
