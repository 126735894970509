.rbc-time-view {
  overflow-y: scroll;
  max-height: 500px;
}

.rbc-timeslot-group {
  min-height: 50px;
}
.rbc-time-content {
  display: none;
}

.rbc-time-header {
  height: 100%;
}

.rbc-label {
    display: none;
}

.rbc-month-view {
 border-radius: 20px;
}

.rbc-header {
    font-weight: normal;
}

.rbc-toolbar-label {
    font-weight: bold;
}

.rbc-today {
    background-color: rgb(240 253 244) !important;
}

.rbc-event, .rbc-day-slot .rbc-background-event {
    width: 95%;
    margin: 0 auto;
}

.rbc-button-link.rbc-show-more {
    padding: 5px;
}

.rbc-allday-cell .rbc-event-content > div {
     display: flex;
    justify-content: space-between;
}

@media(max-width: 768px) {
    .rbc-show-more {
        font-size: 11px;
    }

    .rbc-event-content {
        font-size: 13px;
    }

    .rbc-toolbar-label {
        font-size: 22px;
        margin-bottom: 20px;
    }
}