.logo {
    max-width: 100px;
    padding: 10px;
    margin-bottom: 0;
}

.btn-transparent {
    border: 0;
    padding: 0;
    background: transparent;
    cursor: pointer;
}

.header {
    height: 67px;
    position: fixed;
    width: 100%;
    z-index: 99;
    box-shadow: 0 3px 2px -2px rgba(0,0,0,.1) !important;
}

.MuiDataGrid-columnHeaders {
    background: rgb(243, 244, 246);
}

/* width */
::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #b6b6b6; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #888;  
  }