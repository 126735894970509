@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn-red {
  background-color: #DB0B33;
  color: white;
  border-radius: 6px;
  padding: 5px 10px;

}

.download-btn {
  height: 100%;
  text-transform:none !important;
  box-shadow: none !important;
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.clock {
  font-family: "Roboto Slab", sans-serif;
}

.underline-red {
  text-decoration: underline;
  color:#DB0B33;
}

.navodila {
  height: 100vh;
}

.text-normal {
  text-transform: none !important;
}

.toggleButton {
  color: rgb(117, 117, 117) !important;
  padding: 0 !important;
}

.toggleButton:hover {
  background: none !important;
}

.classic-small-btn {
  text-transform: none !important;
  padding: 0 10px !important;
  font-weight: 100 !important;
}

.danger-btn {
  background-color: #DB0B33 !important;
  color: #fff !important;
}