.spinner-container {
    display: grid;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 67px);
  }

  .spinner-center {
    display: flex;
    height: 100%;
  }